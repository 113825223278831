/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PdfSectionGetById } from '../../models/pdf-section-get-by-id';

export interface ReportsControllerUploadSection$Params {

/**
 * The ID of the project to which the PDF file belongs.
 */
  projectId: string;

/**
 * The type of the content of the uploaded PDF file.
 */
  section: string;

/**
 * The frontend application version in a format 'x.y.z'.
 */
  applicationVersion: string;

/**
 * The optional ID of the variant to which the PDF file belongs.
 */
  variantId?: string;
  
    /**
     * Binary data of the PDF file to be uploaded. The maximum size is 1mb.
     */
    body: string
}

export function reportsControllerUploadSection(http: HttpClient, rootUrl: string, params: ReportsControllerUploadSection$Params, context?: HttpContext): Observable<StrictHttpResponse<PdfSectionGetById>> {
  const rb = new RequestBuilder(rootUrl, reportsControllerUploadSection.PATH, 'post');
  if (params) {
    rb.path('projectId', params.projectId, {});
    rb.query('section', params.section, {});
    rb.query('applicationVersion', params.applicationVersion, {});
    rb.query('variantId', params.variantId, {});
    rb.body(params.body, 'application/pdf');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PdfSectionGetById>;
    })
  );
}

reportsControllerUploadSection.PATH = '/planning-projects/v1/reports/{projectId}/upload-section';
