import { Injectable } from '@angular/core';
import { VariantGetById } from '@customer-apps/api-planning-projects';
import { defaultVariantNameKey, firstVariantNameKey, secondVariantNameKey, thirdVariantNameKey } from '@customer-apps/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { isMaxVariantQuantity, MAX_VARIANT_QUANTITY, CREATE_NEW_VARIANT_ID, getOrder } from '../../../shared/utils';
import { DuplicatePlanningOption, DuplicatePlanningOptionValue, VariantWithTemplateAttributes } from '@customer-apps/shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class VariantsMapper {
    constructor(private translate: TranslateService) {}

    public toVariantsWithTemplateAttributes(variants: VariantGetById[]): VariantWithTemplateAttributes[] {
        return variants.map((variant, index) => ({
            ...variant,
            name: this.getName(index),
            planningDuplicationOptions: this.getPlanningDuplicationOptions(variants, variant.id),
            duplicateVariantDisabled: isMaxVariantQuantity(variants.length)
        }));
    }

    private getName(index: number) {
        const variantNames: string[] = [firstVariantNameKey, secondVariantNameKey, thirdVariantNameKey];
        return this.translate.instant(variantNames[index]);
    }

    private getPlanningDuplicationOptions(variants: VariantGetById[], variantToSkipId: string): DuplicatePlanningOption[] {
        const newVariantSource: DuplicatePlanningOptionValue = {
            id: CREATE_NEW_VARIANT_ID,
            newVariantRequest: {
                order: getOrder(variants),
                name: this.translate.instant(defaultVariantNameKey)
            }
        };
        const optionsSource = variants.length < MAX_VARIANT_QUANTITY ? [...variants, newVariantSource] : variants;
        return optionsSource
            .map((variant, index) => ({
                value: {
                    id: variant.id,
                    newVariantRequest: (variant as DuplicatePlanningOptionValue).newVariantRequest
                },
                info: (variant as DuplicatePlanningOptionValue).newVariantRequest
                    ? this.translate.instant('VARIANT_TILE.CREATE_NEW_VARIANT_ON_DUPLICATION')
                    : null,
                label: this.getName(index)
            }))
            .filter(item => item.value.id !== variantToSkipId);
    }
}
