/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BuildingLocationResponse } from '../models/building-location-response';
import { buildingsControllerConsumptionProfiles } from '../fn/buildings-controller/buildings-controller-consumption-profiles';
import { BuildingsControllerConsumptionProfiles$Params } from '../fn/buildings-controller/buildings-controller-consumption-profiles';
import { buildingsControllerEnergyStandards } from '../fn/buildings-controller/buildings-controller-energy-standards';
import { BuildingsControllerEnergyStandards$Params } from '../fn/buildings-controller/buildings-controller-energy-standards';
import { buildingsControllerFindBuilding } from '../fn/buildings-controller/buildings-controller-find-building';
import { BuildingsControllerFindBuilding$Params } from '../fn/buildings-controller/buildings-controller-find-building';
import { buildingsControllerWindows } from '../fn/buildings-controller/buildings-controller-windows';
import { BuildingsControllerWindows$Params } from '../fn/buildings-controller/buildings-controller-windows';
import { ConsumptionProfilesResponseGetRecordsList } from '../models/consumption-profiles-response-get-records-list';
import { EnergyStandardsResponseGetRecordsList } from '../models/energy-standards-response-get-records-list';
import { WindowsResponseGetRecordsList } from '../models/windows-response-get-records-list';


/**
 * Represents a building related API calls.
 */
@Injectable({ providedIn: 'root' })
export class BuildingsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `buildingsControllerConsumptionProfiles()` */
  static readonly BuildingsControllerConsumptionProfilesPath = '/planning-projects/v1/buildings/consumption-profiles';

  /**
   * Returns all available consumption profiles for given input building parameters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buildingsControllerConsumptionProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingsControllerConsumptionProfiles$Response(params: BuildingsControllerConsumptionProfiles$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsumptionProfilesResponseGetRecordsList>> {
    return buildingsControllerConsumptionProfiles(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all available consumption profiles for given input building parameters.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buildingsControllerConsumptionProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingsControllerConsumptionProfiles(params: BuildingsControllerConsumptionProfiles$Params, context?: HttpContext): Observable<ConsumptionProfilesResponseGetRecordsList> {
    return this.buildingsControllerConsumptionProfiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsumptionProfilesResponseGetRecordsList>): ConsumptionProfilesResponseGetRecordsList => r.body)
    );
  }

  /** Path part for operation `buildingsControllerEnergyStandards()` */
  static readonly BuildingsControllerEnergyStandardsPath = '/planning-projects/v1/buildings/energy-standards';

  /**
   * Returns all available energy standards for given input building parameters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buildingsControllerEnergyStandards()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingsControllerEnergyStandards$Response(params: BuildingsControllerEnergyStandards$Params, context?: HttpContext): Observable<StrictHttpResponse<EnergyStandardsResponseGetRecordsList>> {
    return buildingsControllerEnergyStandards(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all available energy standards for given input building parameters.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buildingsControllerEnergyStandards$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingsControllerEnergyStandards(params: BuildingsControllerEnergyStandards$Params, context?: HttpContext): Observable<EnergyStandardsResponseGetRecordsList> {
    return this.buildingsControllerEnergyStandards$Response(params, context).pipe(
      map((r: StrictHttpResponse<EnergyStandardsResponseGetRecordsList>): EnergyStandardsResponseGetRecordsList => r.body)
    );
  }

  /** Path part for operation `buildingsControllerWindows()` */
  static readonly BuildingsControllerWindowsPath = '/planning-projects/v1/buildings/windows';

  /**
   * Returns all available
   *         window kinds for specific country in specific year of interest. The list
   *         contains also information about the years range during which the window
   *         types are available and the thermal transmission coefficient factor,
   *         expressed in [W/m2 &times; K] units.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buildingsControllerWindows()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingsControllerWindows$Response(params: BuildingsControllerWindows$Params, context?: HttpContext): Observable<StrictHttpResponse<WindowsResponseGetRecordsList>> {
    return buildingsControllerWindows(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all available
   *         window kinds for specific country in specific year of interest. The list
   *         contains also information about the years range during which the window
   *         types are available and the thermal transmission coefficient factor,
   *         expressed in [W/m2 &times; K] units.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buildingsControllerWindows$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingsControllerWindows(params: BuildingsControllerWindows$Params, context?: HttpContext): Observable<WindowsResponseGetRecordsList> {
    return this.buildingsControllerWindows$Response(params, context).pipe(
      map((r: StrictHttpResponse<WindowsResponseGetRecordsList>): WindowsResponseGetRecordsList => r.body)
    );
  }

  /** Path part for operation `buildingsControllerFindBuilding()` */
  static readonly BuildingsControllerFindBuildingPath = '/planning-projects/v1/buildings/find';

  /**
   * Returns information about a project that is of the requested type
   *         and already has a building of the requested type at the specified address.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buildingsControllerFindBuilding()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  buildingsControllerFindBuilding$Response(params: BuildingsControllerFindBuilding$Params, context?: HttpContext): Observable<StrictHttpResponse<BuildingLocationResponse>> {
    return buildingsControllerFindBuilding(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns information about a project that is of the requested type
   *         and already has a building of the requested type at the specified address.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buildingsControllerFindBuilding$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  buildingsControllerFindBuilding(params: BuildingsControllerFindBuilding$Params, context?: HttpContext): Observable<BuildingLocationResponse> {
    return this.buildingsControllerFindBuilding$Response(params, context).pipe(
      map((r: StrictHttpResponse<BuildingLocationResponse>): BuildingLocationResponse => r.body)
    );
  }

}
