import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CreateProjectRequestPost, HeatLoadSelectRequestPost, UpdateProjectRequestPartial } from '@customer-apps/api-planning-projects';
import { AppState } from '@customer-apps/shared/interfaces';
import { ProjectActions } from './project.actions';
import { ProjectSelectors } from './project.selectors';
import { HeatLoadCalculationMethod, ReportConfigurationSection, RouterLinks } from '@customer-apps/shared/enums';

@Injectable({ providedIn: 'root' })
export class ProjectStore {
    public state$ = this.store$.pipe(select(ProjectSelectors.getState()));
    public project$ = this.store$.pipe(select(ProjectSelectors.selectProject()));
    public hasSystemComponentsOrHeatloads$ = this.store$.pipe(select(ProjectSelectors.selectHasSystemComponentsOrHeatloads()));
    public addressCollision$ = this.store$.pipe(select(ProjectSelectors.selectAddressCollision()));
    public address$ = this.store$.pipe(select(ProjectSelectors.selectAddress()));

    constructor(private store$: Store<AppState>) {}

    getProject(payload: { id: string }): void {
        this.store$.dispatch(ProjectActions.getProject(payload));
    }

    createProject(payload: { request: CreateProjectRequestPost }): void {
        this.store$.dispatch(ProjectActions.createProject(payload));
    }

    updateProject(payload: { id: string; request: UpdateProjectRequestPartial; redirectUrl?: string }): void {
        const { id, request, redirectUrl } = payload;
        const actionOptions = { id, request, successAction: ProjectActions.getProjectSuccess, successActionOptions: { redirectUrl } };
        this.store$.dispatch(ProjectActions.updateProject(actionOptions));
    }

    verifyProject(payload: { id: string; request: UpdateProjectRequestPartial; callback?: () => void }): void {
        const { id, request, callback } = payload;
        const actionOptions = {
            id,
            request,
            successAction: ProjectActions.migrationVerificationSuccess,
            successActionOptions: { callback }
        };
        this.store$.dispatch(ProjectActions.updateProject(actionOptions));
    }

    beforeUpdateProject(payload: { id: string; request: UpdateProjectRequestPartial; redirectUrl?: string }): void {
        this.store$.dispatch(ProjectActions.beforeUpdateProject(payload));
    }

    addressCollision(): void {
        this.store$.dispatch(ProjectActions.addressCollision());
    }

    savePhotovoltaicIntermediary(payload: { projectId: string; request: UpdateProjectRequestPartial }): void {
        this.store$.dispatch(ProjectActions.savePhotovoltaicIntermediary(payload));
    }

    saveHeatPumpIntermediary(payload: { id: string; request: UpdateProjectRequestPartial }): void {
        this.store$.dispatch(ProjectActions.saveHeatPumpIntermediary(payload));
    }

    saveHeatload(payload: { projectPatch: UpdateProjectRequestPartial; redirectUrl: string }): void {
        this.store$.dispatch(ProjectActions.saveHeatload(payload));
    }

    deleteHeatload(method: HeatLoadCalculationMethod): void {
        this.store$.dispatch(ProjectActions.deleteHeatload({ method }));
    }

    selectHeatload(request: HeatLoadSelectRequestPost): void {
        this.store$.dispatch(ProjectActions.selectHeatload({ request }));
    }

    closeProject(projectId: string): void {
        this.store$.dispatch(ProjectActions.closeProject({ projectId }));
    }

    completeProject(projectId: string): void {
        this.store$.dispatch(ProjectActions.completeProject({ projectId }));
    }

    reloadProject(projectId: string): void {
        this.store$.dispatch(
            ProjectActions.reloadProject({
                id: projectId,
                successActionOptions: { redirectUrl: `${RouterLinks.Project}${projectId}` },
                successAction: ProjectActions.getProjectSuccess
            })
        );
    }

    uploadProjectReportSection(payload: {
        body: string;
        projectId: string;
        section: ReportConfigurationSection;
        applicationVersion: string;
    }): void {
        this.store$.dispatch(ProjectActions.uploadProjectReportSection(payload));
    }
}
