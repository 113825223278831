import { Injectable } from '@angular/core';
import { HeatGeneratorInformation } from '@customer-apps/api-planning-projects';
import { AccordionService, HeatGeneratorFormValue } from '@customer-apps/shared/interfaces';
import { map, withLatestFrom } from 'rxjs';
import { ProjectStore } from '../../state/project/project.store';
import { HeatPumpIntermediaryStore } from '../../state/heat-pump-intermediary/heat-pump-intermediary.store';
import { HeatloadFormOptionsService, HeatloadFormService } from '../../heatload/services';
import { getSelectedSourceFromGenerators } from '../../shared/utils/selected-energy-source.helper';
import { FormName, HeatLoadCalculationMethod, ProjectType, SuccessMessages } from '@customer-apps/shared/enums';
import { HeatPumpIntermediaryPrefillService } from './heat-pump-intermediary-prefill.service';
import { HeatloadPrefillMapper, ProjectsService } from '../../services';

@Injectable({
    providedIn: 'root'
})
export class HeatPumpIntermediaryEnergySourceService implements AccordionService {
    constructor(
        private projectStore: ProjectStore,
        private prefillMappper: HeatloadPrefillMapper,
        private projectsService: ProjectsService,
        private heatPumpIntermediaryStore: HeatPumpIntermediaryStore,
        private formService: HeatloadFormService,
        private formOptionsService: HeatloadFormOptionsService,
        private prefillService: HeatPumpIntermediaryPrefillService
    ) {}

    public questions$ = this.heatPumpIntermediaryStore.heatGenerators$.pipe(
        withLatestFrom(this.projectStore.project$, this.heatPumpIntermediaryStore.cache$),
        map(([heatGenerators, project, cache]) => ({
            heatGenerators,
            project,
            cache
        })),
        map(state => {
            if (!state.heatGenerators) {
                return null;
            }

            const cachePrefill = this.prefillMappper.stateToEnergySourcePrefill(state.cache?.heatingEnergySource);
            const projectPrefill = this.prefillMappper.projectToEnergySourcePrefill(state.project);
            const options = this.formOptionsService.getEnergySourceFormOptions({
                prefill: cachePrefill || projectPrefill,
                heatGenerators: state.heatGenerators
            });
            return this.formService.getEnergySourceQuestions(options);
        })
    );

    public getSuccessMessage(generators: HeatGeneratorInformation[], formValue: HeatGeneratorFormValue): string {
        const energySource = getSelectedSourceFromGenerators(generators, formValue.heatGenerator, formValue.specificEnergySource);
        return `${SuccessMessages.HeatingEnergySource}.${energySource.type}`;
    }

    public isPrefillNeeded(): boolean {
        return !this.prefillService.isPrefilled(FormName.EnergySource);
    }

    public afterPrefill(): void {
        this.prefillService.setAsPrefilled(FormName.EnergySource);
    }

    public isExpanded$ = this.heatPumpIntermediaryStore.sameValueSubmited$.pipe(
        withLatestFrom(this.projectStore.project$, (form, project) => ({ form, project })),
        map(({ form, project }) => {
            const afterDeviceForm =
                form?.formName === FormName.Device &&
                this.projectsService.isRenovation(project!.projectType as ProjectType) &&
                (this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.LivingSpace) ||
                    this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.BuildingHull));

            const afterBuildingForm =
                form?.formName === FormName.Building &&
                this.projectsService.isRenovation(project!.projectType as ProjectType) &&
                (this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.Consumption) ||
                    this.projectsService.isCalculatedBy(project!, HeatLoadCalculationMethod.CustomValue));

            return afterDeviceForm || afterBuildingForm;
        })
    );
}
