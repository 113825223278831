/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UpdateHeatPumpPlanningRequestPartial } from '../../models/update-heat-pump-planning-request-partial';
import { UpdatePhotovoltaicPlanningRequestPartial } from '../../models/update-photovoltaic-planning-request-partial';
import { UpdatePlanningResponse } from '../../models/update-planning-response';
import { UpdateVentilationPlannerPlanningRequestPartial } from '../../models/update-ventilation-planner-planning-request-partial';
import { UpdateViGuidePlanningRequestPartial } from '../../models/update-vi-guide-planning-request-partial';

export interface PlanningsControllerPatchPlanning$Params {

/**
 * Planning id.
 */
  id: string;
  
    /**
     * Patching request - contains only fields to be modified.
     */
    body?: (UpdateHeatPumpPlanningRequestPartial | UpdatePhotovoltaicPlanningRequestPartial | UpdateVentilationPlannerPlanningRequestPartial | UpdateViGuidePlanningRequestPartial)
}

export function planningsControllerPatchPlanning(http: HttpClient, rootUrl: string, params: PlanningsControllerPatchPlanning$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdatePlanningResponse>> {
  const rb = new RequestBuilder(rootUrl, planningsControllerPatchPlanning.PATH, 'patch');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UpdatePlanningResponse>;
    })
  );
}

planningsControllerPatchPlanning.PATH = '/planning-projects/v1/projects/variants/plannings/{id}';
