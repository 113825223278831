import { Injectable } from '@angular/core';
import { EnergyCertificateGetById, EnergyStandard, ProjectResponseGetById } from '@customer-apps/api-planning-projects';
import {
    BuildingPlacement,
    ConsumptionProfile,
    EnergySource,
    FormKeys,
    HeatGeneratorType,
    HeatingDistributionMethod,
    HeatingSystemType,
    HeatPumpOperatingMode,
    RoofType,
    WindowType
} from '@customer-apps/shared/enums';
import {
    BuildingHull,
    BuildingHullFormValue,
    DistributionAndScheduleFormValue,
    EnergyCertificateFormValue,
    EnergyConsumptionFormValue,
    EnergyStandardFormValue,
    HeatGeneratorDetails,
    HeatGeneratorDetailsFormValue,
    HeatGeneratorFormValue,
    HeatingDistribution,
    HeatingEnergySourceState,
    LivingAreaFormValue,
    Modernization,
    ModernizationsAndInsulation,
    ModernizationsAndInsulationFormValue,
    ModernizationsFormValue,
    RoofDimensions,
    RoofDimensionsFormValue,
    RoofTypeFormValue,
    VentilationFormValue,
    WindowsAndGlazing,
    WindowsAndGlazingFormValue
} from '@customer-apps/shared/interfaces';
import { LodashService } from '@customer-apps/shared/services';
import { fractionToPercent, metersToCentimeters, numberToWindowAreaDictionary } from '@customer-apps/shared/utils';

@Injectable({
    providedIn: 'root'
})
export class HeatloadPrefillMapper {
    public projectToLivingAreaPrefill(project: ProjectResponseGetById): LivingAreaFormValue | undefined {
        return project.building.livingArea ? { [FormKeys.LivingArea]: project.building.livingArea } : undefined;
    }

    public stateToLivingAreaPrefill(state?: { livingArea?: { value: number } }): LivingAreaFormValue | undefined {
        return state?.livingArea?.value ? { [FormKeys.LivingArea]: state?.livingArea?.value } : undefined;
    }

    public projectToModernizationPrefill(project: ProjectResponseGetById | undefined): ModernizationsFormValue | undefined {
        if (!project) {
            return undefined;
        }
        return {
            [FormKeys.BuildingConstructionYear]: project.building.buildingConstructionYear?.toString()!,
            [FormKeys.RoofModernization]: project.building.roofConstructionYear?.toString()!,
            [FormKeys.WallsModernization]: project.building.wallsConstructionYear?.toString()!,
            [FormKeys.WindowsModernization]: project.building.windowsConstructionYear?.toString()!
        };
    }

    public stateToModernizationPrefill(state: Modernization | undefined): ModernizationsFormValue | undefined {
        if (!state) {
            return undefined;
        }
        return {
            [FormKeys.BuildingConstructionYear]: state?.buildingConstructionYear!,
            [FormKeys.RoofModernization]: state?.roofModernization!,
            [FormKeys.WallsModernization]: state?.wallsModernization!,
            [FormKeys.WindowsModernization]: state?.windowsModernization!
        };
    }

    public projectToVentilationPrefill(project: ProjectResponseGetById | undefined): VentilationFormValue | undefined {
        return !LodashService.isNil(project?.building.hasVentilation)
            ? { [FormKeys.Ventilation]: project?.building.hasVentilation! }
            : undefined;
    }

    public stateToVentilationPrefill(state?: { ventilation?: { value: any } }): VentilationFormValue | undefined {
        return !LodashService.isNil(state?.ventilation?.value) ? { [FormKeys.Ventilation]: state?.ventilation?.value } : undefined;
    }

    public projectToBuildingHullPrefill(project: ProjectResponseGetById | undefined): BuildingHullFormValue | undefined {
        if (!project) {
            return undefined;
        }

        return {
            [FormKeys.BuildingDimensions]: {
                [FormKeys.BuildingLength]: project.building.buildingLength!,
                [FormKeys.BuildingWidth]: project.building.buildingWidth!,
                [FormKeys.FloorHeight]: project.building.buildingLevelHeight!,
                [FormKeys.NumberOfFloors]: project.building.buildingLevelsCount!
            },
            [FormKeys.BuildingPosition]: project.building.buildingPlacement as BuildingPlacement,
            [FormKeys.VentilationAndHeating]: {
                [FormKeys.VentilationPresent]: project.building.hasVentilation!,
                [FormKeys.AtticPresent]: project.building.hasAttic!,
                [FormKeys.AtticHeated]: project.building.heatedAttic!,
                [FormKeys.BasementPresent]: project.building.hasCellar!,
                [FormKeys.BasementHeated]: project.building.heatedCellar!
            }
        };
    }

    public stateToBuildingHullPrefill(state?: BuildingHull): BuildingHullFormValue | undefined {
        if (!state) {
            return undefined;
        }

        return {
            [FormKeys.BuildingDimensions]: {
                [FormKeys.BuildingLength]: state.buildingDimensions.buildingLength,
                [FormKeys.BuildingWidth]: state.buildingDimensions.buildingWidth,
                [FormKeys.FloorHeight]: state.buildingDimensions.floorHeight,
                [FormKeys.NumberOfFloors]: state.buildingDimensions.numberOfFloors
            },
            [FormKeys.BuildingPosition]: state?.buildingPosition,
            [FormKeys.VentilationAndHeating]: {
                [FormKeys.VentilationPresent]: state.ventilationAndHeating.ventilationPresent!,
                [FormKeys.AtticPresent]: state.ventilationAndHeating.atticPresent!,
                [FormKeys.AtticHeated]: state.ventilationAndHeating.atticHeated!,
                [FormKeys.BasementPresent]: state.ventilationAndHeating.basementPresent!,
                [FormKeys.BasementHeated]: state.ventilationAndHeating.basementHeated!
            }
        };
    }

    public projectToModernizationsAndInsulationPrefill(
        project: ProjectResponseGetById | undefined
    ): ModernizationsAndInsulationFormValue | undefined {
        if (!project) {
            return undefined;
        }

        return {
            [FormKeys.BuildingConstructionYear]: project?.building.buildingConstructionYear?.toString()!,
            [FormKeys.WindowsConstructionYear]: project?.building.windowsConstructionYear?.toString()!,
            [FormKeys.RoofRenovationAndInsulation]: {
                [FormKeys.RoofRenovationYear]: project?.building.roofConstructionYear?.toString()!,
                [FormKeys.RoofInsulation]: metersToCentimeters(project?.building.roofInsulation)!
            },
            [FormKeys.UpperFloorRenovationAndInsulation]: {
                [FormKeys.AtticRenovationYear]: project?.building.atticConstructionYear?.toString()!,
                [FormKeys.UpperFloorInsulation]: metersToCentimeters(project?.building.atticInsulation)!
            },
            [FormKeys.WallsRenovationAndInsulation]: {
                [FormKeys.WallsRenovationYear]: project?.building.wallsConstructionYear?.toString()!,
                [FormKeys.WallsInsulation]: metersToCentimeters(project?.building.wallsInsulation)!
            },
            [FormKeys.BasementRenovationAndInsulation]: {
                [FormKeys.BasementRenovationYear]: project?.building.cellarConstructionYear?.toString()!,
                [FormKeys.BasementInsulation]: metersToCentimeters(project?.building.cellarInsulation)!
            }
        };
    }

    public stateToModernizationsAndInsulationPrefill(
        state?: ModernizationsAndInsulation
    ): ModernizationsAndInsulationFormValue | undefined {
        if (!state) {
            return undefined;
        }

        return {
            [FormKeys.BuildingConstructionYear]: state.buildingConstructionYear!,
            [FormKeys.WindowsConstructionYear]: state.windowsConstructionYear!,
            [FormKeys.RoofRenovationAndInsulation]: {
                [FormKeys.RoofRenovationYear]: state.roofRenovationAndInsulation?.roofConstructionYear!,
                [FormKeys.RoofInsulation]: state.roofRenovationAndInsulation?.roofInsulation! || state.roofInsulation!
            },
            [FormKeys.UpperFloorRenovationAndInsulation]: {
                [FormKeys.AtticRenovationYear]: state.upperFloorRenovationAndInsulation?.atticConstructionYear!,
                [FormKeys.UpperFloorInsulation]: state.upperFloorRenovationAndInsulation?.atticInsulation! || state.atticInsulation!
            },
            [FormKeys.WallsRenovationAndInsulation]: {
                [FormKeys.WallsRenovationYear]: state.wallsRenovationAndInsulation?.wallsConstructionYear!,
                [FormKeys.WallsInsulation]: state.wallsRenovationAndInsulation?.wallsInsulation! || state.wallsInsulation!
            },
            [FormKeys.BasementRenovationAndInsulation]: {
                [FormKeys.BasementRenovationYear]: state.basementRenovationAndInsulation?.cellarConstructionYear!,
                [FormKeys.BasementInsulation]: state.basementRenovationAndInsulation?.cellarInsulation! || state.cellarInsulation!
            }
        };
    }

    public projectToWindowsAndGlazingPrefill(project: ProjectResponseGetById | undefined): WindowsAndGlazingFormValue | undefined {
        if (!project) {
            return undefined;
        }

        return {
            [FormKeys.WindowsPercentageArea]: numberToWindowAreaDictionary[+project?.building.windowsArea],
            [FormKeys.TypeOfGlazing]: project?.building.windowsType as WindowType
        };
    }

    public stateToWindowsAndGlazingPrefill(state: WindowsAndGlazing | undefined): WindowsAndGlazingFormValue | undefined {
        if (!state) {
            return undefined;
        }

        return {
            [FormKeys.WindowsPercentageArea]: state?.windowsPercentageArea,
            [FormKeys.TypeOfGlazing]: state?.typeOfGlazing
        };
    }
    public projectToRoofTypePrefill(project: ProjectResponseGetById | undefined): RoofTypeFormValue | undefined {
        if (!project) {
            return undefined;
        }

        return {
            [FormKeys.RoofType]: project.building.roof?.type as RoofType
        };
    }

    public stateToRoofTypePrefill(state: { value: RoofType } | undefined): RoofTypeFormValue | undefined {
        if (!state) {
            return undefined;
        }

        return {
            [FormKeys.RoofType]: state?.value
        };
    }

    public projectToRoofDimensionsPrefill(project: ProjectResponseGetById | undefined): RoofDimensionsFormValue | undefined {
        if (!project) {
            return undefined;
        }

        return {
            [FormKeys.RoofDimensions]: {
                [FormKeys.HeightOfJamb]: project.building.roof?.jambWallHeight,
                [FormKeys.RoofHeight1]: project.building.roof?.height1,
                [FormKeys.RoofHeight2]: project.building.roof?.height2,
                [FormKeys.RoofLength]: project.building.roof?.s1,
                [FormKeys.Inclination]: LodashService.isNil(project?.building.roof?.slope2) ? project?.building.roof?.slope1 : undefined,
                [FormKeys.Inclination1]: LodashService.isNil(project?.building.roof?.slope2) ? undefined : project?.building.roof?.slope1,
                [FormKeys.Inclination2]: project?.building.roof?.slope2
            }
        };
    }

    public stateToRoofDimensionsPrefill(state: RoofDimensions | undefined): RoofDimensionsFormValue | undefined {
        if (!state) {
            return undefined;
        }

        return {
            [FormKeys.RoofDimensions]: {
                [FormKeys.HeightOfJamb]: state.heightOfJamb,
                [FormKeys.RoofHeight1]: state.roofHeight1,
                [FormKeys.RoofHeight2]: state.roofHeight2,
                [FormKeys.RoofLength]: state.roofLength,
                [FormKeys.Inclination]: state.inclination,
                [FormKeys.Inclination1]: state.inclination1,
                [FormKeys.Inclination2]: state.inclination2
            }
        };
    }

    public projectToEnergySourcePrefill(project: ProjectResponseGetById | undefined): HeatGeneratorFormValue | undefined {
        if (!project) {
            return undefined;
        }
        return {
            [FormKeys.HeatGenerator]: project.building.currentHeatingSystem?.heatGeneratorType as HeatGeneratorType,
            [FormKeys.EnergySource]: project.building.currentHeatingSystem?.energySource as EnergySource
        };
    }

    public stateToEnergySourcePrefill(state: HeatingEnergySourceState | undefined): HeatGeneratorFormValue | undefined {
        if (!state) {
            return undefined;
        }
        return {
            [FormKeys.HeatGenerator]: state.selectedHeatGenerator,
            [FormKeys.EnergySource]: state.selectedEnergySource.type as EnergySource
        };
    }

    public projectToHeatGeneratorDetailsPrefill(project: ProjectResponseGetById | undefined): HeatGeneratorDetailsFormValue | undefined {
        if (!project) {
            return undefined;
        }
        const currentHeatingSystem = project?.building.currentHeatingSystem;
        return {
            [FormKeys.InstallationYear]: currentHeatingSystem?.installationConstructionYear?.toString()!,
            [FormKeys.BoilerType]: currentHeatingSystem?.heatingSystemType as HeatingSystemType,
            [FormKeys.BoilerTypeSubquestion]: {
                [FormKeys.LowTemperature]: currentHeatingSystem?.isLowTemperature,
                [FormKeys.WarmWaterByHeatGenerator]: project.building.warmWaterByHeatGenerator
            },
            [FormKeys.GasLossExhaust]: fractionToPercent(currentHeatingSystem?.exhaustGasLosses),
            [FormKeys.GasLossExhaustKnown]: {
                [FormKeys.IsKnown]: Boolean(currentHeatingSystem?.exhaustGasLosses)
            },
            [FormKeys.NominalPower]: currentHeatingSystem?.heatGeneratorPower!,
            [FormKeys.HeatPumpOperatingMode]: currentHeatingSystem?.heatPumpOperatingMode as HeatPumpOperatingMode
        };
    }

    public stateToHeatGeneratorDetailsPrefill(state: HeatGeneratorDetails | undefined): HeatGeneratorDetailsFormValue | undefined {
        if (!state) {
            return undefined;
        }

        return {
            [FormKeys.BoilerType]: state.boilerType!,
            [FormKeys.BoilerTypeSubquestion]: {
                [FormKeys.LowTemperature]: state.lowTemperature,
                [FormKeys.WarmWaterByHeatGenerator]: state.warmWaterByHeatGenerator
            },
            [FormKeys.GasLossExhaust]: state.gasLossExhaust,
            [FormKeys.GasLossExhaustKnown]: {
                [FormKeys.IsKnown]: state.gasLossExhaustKnown
            },
            [FormKeys.InstallationYear]: state.installationYear!,
            [FormKeys.NominalPower]: state.nominalPower!,
            [FormKeys.HeatPumpOperatingMode]: state.heatPumpOperatingMode
        };
    }

    public projectToEnergyConsumptionPrefill(project: ProjectResponseGetById | undefined): EnergyConsumptionFormValue | undefined {
        if (!project) {
            return undefined;
        }

        return {
            [FormKeys.RequiredEnergyPerYear]: project.building.currentHeatingSystem?.consumption!,
            [FormKeys.NonHeatingElectricityPerYear]: project.building.electricityDemand!
        };
    }

    public stateToEnergyConsumptionPrefill(state: EnergyConsumptionFormValue | undefined): EnergyConsumptionFormValue | undefined {
        if (!state) {
            return undefined;
        }

        return {
            [FormKeys.RequiredEnergyPerYear]: state.requiredEnergyPerYear,
            [FormKeys.NonHeatingElectricityPerYear]: state.nonHeatingElectricityPerYear
        };
    }

    public projectToHeatingDistributionPrefill(project: ProjectResponseGetById | undefined): DistributionAndScheduleFormValue | undefined {
        if (!project) {
            return undefined;
        }

        return {
            [FormKeys.HeatingFlowTemperature]: project.building.flowTemperature!,
            [FormKeys.DistributionMethod]: project.building.heatingType as HeatingDistributionMethod,
            [FormKeys.HeatingSchedule]: project.building.consumptionProfileForHeating as ConsumptionProfile
        };
    }

    public stateToHeatingDistributionPrefill(state: HeatingDistribution | undefined): DistributionAndScheduleFormValue | undefined {
        if (!state) {
            return undefined;
        }

        return {
            [FormKeys.DistributionMethod]: state.distributionMethod,
            [FormKeys.HeatingFlowTemperature]: state.heatingFlowTemperature,
            [FormKeys.HeatingSchedule]: state.heatingSchedule
        };
    }

    public projectToEnergyCertificatePrefill(project: ProjectResponseGetById | undefined): EnergyCertificateFormValue | undefined {
        if (!project) {
            return undefined;
        }

        return {
            [FormKeys.AirExchangeRate]: project?.building.energyCertificate?.airExchangeRate!,
            [FormKeys.HeatLossTransmission]: project?.building.energyCertificate?.specificTransmissionHeatLoss!,
            [FormKeys.BuildingVolume]: project?.building.energyCertificate?.buildingVolume!,
            [FormKeys.SurroundingArea]: project?.building.energyCertificate?.surroundingArea!
        };
    }

    public stateToEnergyCertificatePrefill(state: EnergyCertificateGetById | undefined): EnergyCertificateFormValue | undefined {
        if (!state) {
            return undefined;
        }

        return {
            [FormKeys.AirExchangeRate]: state.airExchangeRate!,
            [FormKeys.HeatLossTransmission]: state.specificTransmissionHeatLoss!,
            [FormKeys.BuildingVolume]: state.buildingVolume!,
            [FormKeys.SurroundingArea]: state.surroundingArea!
        };
    }

    public projectToEnergyStandardPrefill(
        project: ProjectResponseGetById | undefined,
        allStandards: EnergyStandard[]
    ): EnergyStandardFormValue | undefined {
        if (!project) {
            return undefined;
        }
        const selectedStandard = allStandards.find(standard => standard.key === project?.building.energyStandard)!;

        return {
            [FormKeys.EnergyStandards]: selectedStandard
        };
    }

    public stateToEnergyStandardPrefill(state: EnergyStandard | undefined): EnergyStandardFormValue | undefined {
        if (!state) {
            return undefined;
        }

        return {
            [FormKeys.EnergyStandards]: state
        };
    }
}
