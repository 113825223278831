import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from './components/icon/icon.component';
import { FormComponent } from './components/form/form.component';
import { QuestionComponent } from './components/question/question.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ListComponent } from './components/list/list.component';
import { ChipSuccessComponent } from './components/chip-success/chip-success.component';
import { CustomAnswerRadioComponent } from './components/custom-answer-radio/custom-answer-radio.component';
import { SliderComponent } from './components/slider/slider.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { MultiTextboxComponent } from './components/multi-textbox/multi-textbox.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { AddressboxComponent } from './components/addressbox/addressbox.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MessageComponent } from './components/message/message.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { TuvLogoComponent } from './components/tuv-logo/tuv-logo.component';
import { JumbotronComponent } from './components/jumbotron/jumbotron.component';
import { StatusComponent } from './components/status/status.component';
import { SnackbarDirective } from './directives/snackbar.directive';
import { ConfirmDirective } from './components/confirm/confirm.directive';
import { ImagePlaceholderDirective } from './directives/image-placeholder.directive';
import { JoinPipe } from './pipes/join.pipe';
import { EnergySourceToIconPipe } from './pipes/energy-source-to-icon.pipe';
import { AsPipe } from './pipes/as.pipe';
import { FormFieldStylesPipe } from './pipes/form-field-styles.pipe';
import { IsNilPipe } from './pipes/is-nil.pipe';
import { CountryCodePipe } from './pipes/country-code.pipe';
import { LanguageCodePipe } from './pipes/language-code.pipe';
import { PrefixPipe } from './pipes/prefix.pipe';
import { TotalHeatloadPipe } from './pipes/total-heatload.pipe';
import { MessageTypeToIconPipe } from './pipes/message-type-to-icon.pipe';
import { MaterialModule } from './material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';
import { ActiveAnchorDirective } from './directives/active-anchor.directive';
import { DisableOnPipe } from './pipes/disable-on.pipe';
import { IsOfCountryPipe } from './pipes/is-of-country.pipe';
import { ProjectStatusStylesPipe } from './pipes/project-status-styles.pipe';
import { FormErrorMessageComponent } from './components/form-error-message/form-error-message.component';
import { CompanyTileComponent } from './components/company-tile/company-tile.component';
import { ViewHostDirective } from './directives/view-host.directive';
import { SearchDropdownComponent } from './components/search-dropdown/search-dropdown.component';
import { GtmEventDirective } from './directives/gtm-event.directive';
import { MarkdownItPipe } from './pipes/markdown-it.pipe';
import { GegAllowSubsidyCalculatorLinkPipe } from './pipes/geg-allow-subsidy-calculator-link.pipe';
import { GegRecommendationIconPipe } from './pipes/geg-recommendation-icon.pipe';
import { GegRecommendationTypePipe } from './pipes/geg-recommendation-type.pipe';
import { WidthTooltipDirective } from './directives/width-tooltip.directive';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ProductTechnicalDataPipe } from './pipes/product-technical-data.pipe';
import { IsReadOnlyPipe } from './pipes/is-read-only.pipe';
import { IsOfStatusPipe } from './pipes/is-of-status.pipe';
import { HasPartnerPipe } from './pipes/has-partner.pipe';
import { OrderStatusStylesPipe } from './pipes/order-status-styles.pipe';
import { OrderStatusDictionaryPipe } from './pipes/order-status-dictionary.pipe';
import { FilterBySystemTypePipe } from './pipes/filter-by-system-type.pipe';

const COMPONENTS = [
    IconComponent,
    FormComponent,
    QuestionComponent,
    NavbarComponent,
    ListComponent,
    ChipSuccessComponent,
    CustomAnswerRadioComponent,
    SliderComponent,
    CheckboxComponent,
    MultiTextboxComponent,
    InfoBoxComponent,
    DropdownComponent,
    AddressboxComponent,
    SpinnerComponent,
    MessageComponent,
    ConfirmComponent,
    TuvLogoComponent,
    JumbotronComponent,
    FormErrorMessageComponent,
    StatusComponent,
    CompanyTileComponent,
    SearchDropdownComponent,
    PaginatorComponent
];
const DIRECTIVES = [
    WidthTooltipDirective,
    SnackbarDirective,
    ConfirmDirective,
    SnackbarDirective,
    ImagePlaceholderDirective,
    ActiveAnchorDirective,
    ViewHostDirective,
    GtmEventDirective
];
const PIPES = [
    JoinPipe,
    EnergySourceToIconPipe,
    AsPipe,
    FormFieldStylesPipe,
    IsNilPipe,
    CountryCodePipe,
    LanguageCodePipe,
    PrefixPipe,
    TotalHeatloadPipe,
    MessageTypeToIconPipe,
    DisableOnPipe,
    IsOfCountryPipe,
    ProjectStatusStylesPipe,
    OrderStatusStylesPipe,
    OrderStatusDictionaryPipe,
    MarkdownItPipe,
    GegAllowSubsidyCalculatorLinkPipe,
    GegRecommendationIconPipe,
    GegRecommendationTypePipe,
    ProductTechnicalDataPipe,
    IsReadOnlyPipe,
    IsOfStatusPipe,
    HasPartnerPipe,
    FilterBySystemTypePipe
];

@NgModule({
    declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
    imports: [CommonModule, MaterialModule, ReactiveFormsModule, RouterModule, NgxMaskDirective, NgxMaskPipe, TranslateModule],
    exports: [NgxMaskDirective, NgxMaskPipe, TranslateModule, MaterialModule, ReactiveFormsModule, ...COMPONENTS, ...DIRECTIVES, ...PIPES],
    providers: [provideEnvironmentNgxMask()]
})
export class SharedUiModule {}
