import { EnergySource, HeatGeneratorType, HeatingSystemType, HeatPumpOperatingMode } from '@customer-apps/shared/enums';
import { EnumDictionary } from '@customer-apps/shared/utils';

export const partnerPortalFAQUrl: string = 'https://partnerportal.viessmann.com/de/de/support/faq/geg-check.html';
export const gegSubsidyCalculatorURL: string = 'https://app.foerder-profi.de/';

/**
 * Used in {@see GegSystemTranslationsResolverService} as a prefix of all system translation keys.
 */
export const gegSystemTranslationPrefix: string = 'GEG_SYSTEM.';

/**
 * Fix the translation phrases used in keys generation, to not tightly couple the Phrase keys and enums.
 */
export const gegSystemsTranslationKeys: EnumDictionary<
    EnergySource | HeatGeneratorType | HeatingSystemType | HeatPumpOperatingMode | 'MONOENERGETIC',
    string
> = {
    [EnergySource.HeatingOil]: 'HEATING_OIL',
    [EnergySource.Gas]: 'GAS', // alias = Natural gas
    [EnergySource.Electricity]: 'ELECTRICITY',
    [EnergySource.HeatPumpElectricity]: 'ELECTRICITY_HEAT_PUMP',
    [EnergySource.ElectricityFeed]: 'ELECTRICITY_FEED',
    [EnergySource.ElectricityOwnNeeds]: 'ELECTRICITY_OWN_NEEDS',
    [EnergySource.ElectricityProduced]: 'ELECTRICITY_PRODUCED',
    [EnergySource.Pellet]: 'PELLET',
    [EnergySource.Softwood]: 'SOFTWOOD',
    [EnergySource.Hardwood]: 'HARDWOOD',
    [EnergySource.Methane]: 'METHANE',
    [EnergySource.Hydrogen]: 'HYDROGEN',
    [EnergySource.SolarThermal]: 'SOLAR_THERMAL',
    [EnergySource.DistrictHeating]: 'DISTRICT_HEATING',
    [HeatGeneratorType.GasBoiler]: 'GAS_BOILER',
    [HeatGeneratorType.OilBoiler]: 'OIL_BOILER',
    [HeatGeneratorType.WoodStove]: 'WOOD_STOVE',
    [HeatGeneratorType.HeatPump]: 'HEAT_PUMP',
    [HeatGeneratorType.ElectricHeater]: 'ELECTRIC_HEATER',
    // [HeatGeneratorType.SolarThermal]: 'SOLAR_THERMAL',
    // [HeatGeneratorType.DistrictHeating]: 'DISTRICT_HEATING',
    [HeatingSystemType.CondensingBoiler]: 'CONDENSING_BOILER',
    [HeatingSystemType.SolidFuelBoiler]: 'SOLID_FUEL_BOILER',
    [HeatingSystemType.SpecialGasBoiler]: 'GAS_SPECIAL_BOILER',
    [HeatingSystemType.FanBoiler]: 'FAN_BOILER',
    [HeatingSystemType.FanBoilerChangedBurner]: 'FAN_BOILER_WITH_CHANGED_BURNER',
    [HeatingSystemType.CirculatingWaterBoiler]: 'CIRCULATION_WATER_BOILER',
    [HeatingSystemType.TwoFuelChangeBoiler]: 'TWO_FUEL_CHANGE_BOILER',
    [HeatingSystemType.GroundwaterHeatPump]: 'WATER',
    [HeatingSystemType.SoilHeatPump]: 'SOIL',
    [HeatingSystemType.AirHeatPump]: 'AIR',
    // [HeatingSystemType.ElectricHeater]: 'ELECTRIC_HEATER',
    // [HeatingSystemType.SolarThermal]: 'SOLAR_THERMAL',
    // [HeatingSystemType.DistrictHeating]: 'DISTRICT_HEATING',
    [HeatPumpOperatingMode.Parallel]: 'PARALLEL',
    [HeatPumpOperatingMode.Monovalent]: 'MONOVALENT',
    [HeatPumpOperatingMode.Alternate]: 'ALTERNATE',

    // "MONOENERGETIC" is just a backend internal value not used on frontend.
    MONOENERGETIC: 'MONOENERGETIC'
};

/**
 * Used in {@see GegSystemTranslationsResolverService} to build translation keys.
 */
export const gegSystemLowTempBoilerKey: string = 'LOW_TEMP';

/**
 * Used in {@see GegSystemTranslationsResolverService} to build translation keys.
 */
export const gegSystemHighTempBoilerKey: string = 'HIGH_TEMP';

/**
 * Used in {@see GegSystemTranslationsResolverService} to build translation keys.
 */
export const gegSystemCondensingBoilerKey: string = 'CONDENSING';

/**
 * Used in {@see GegSystemTranslationsResolverService} to build translation keys.
 */
export const gegSystemNonCondensingBoilerKey: string = 'NON_CONDENSING';
