import { Injectable } from '@angular/core';
import { Question } from '@customer-apps/shared/utils';
import { BehaviorSubject, distinctUntilChanged, EMPTY, map, merge, mergeMap, Observable, of, withLatestFrom } from 'rxjs';
import { GEGStore } from '../../state/geg/geg.store';
import { HeatloadFormOptionsService, HeatloadFormService } from '../../heatload/services';
import { BuildingType, FormName, GEGSystemType } from '@customer-apps/shared/enums';
import { ProjectStore } from '../../state/project/project.store';
import { GegPrefillService } from './geg-prefill.service';
import { TranslateService } from '@ngx-translate/core';
import { HeatGeneratorDetailsFormValue } from '@customer-apps/shared/interfaces';
import { LodashService } from '@customer-apps/shared/services';
import { HeatloadPrefillMapper } from '../../services';

@Injectable({
    providedIn: 'root'
})
export class GegCurrentSystemHeatGeneratorDetailsService {
    constructor(
        private projectStore: ProjectStore,
        private formOptionsService: HeatloadFormOptionsService,
        private prefillMapper: HeatloadPrefillMapper,
        private prefillService: GegPrefillService,
        private translate: TranslateService,
        private formService: HeatloadFormService,
        private gegStore: GEGStore
    ) {}

    public questions$: Observable<Question[] | null> = merge(this.onEnergySource$(), this.onHeatingSystems$()).pipe(distinctUntilChanged());
    /**
     * Sometimes it is possible only to prefill just the installation year. It is needed to send
     * an event that the prefilling is finished even though it was not a full prefill.
     */
    private isConstructionYearPrefillFinished$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private onEnergySource$() {
        return this.gegStore.currentSystemEnergySource$.pipe(
            distinctUntilChanged((prev, curr) => LodashService.isEqual(prev, curr)),
            withLatestFrom(
                this.gegStore.currentSystemHeatGeneratorDetails$,
                this.projectStore.project$,
                this.gegStore.cache$,
                (energySource, heatGeneratorDetails, project, cache) => ({
                    energySource,
                    project,
                    statePrefillSource: heatGeneratorDetails,
                    cachePrefillSource: cache?.currentSystem?.heatGeneratorDetails
                })
            ),
            mergeMap(({ energySource, statePrefillSource, cachePrefillSource, project }) => {
                if (!energySource) {
                    return of(null);
                }
                const installationConstructionYear = statePrefillSource?.installationYear || cachePrefillSource?.installationYear;
                if (this.isSimplePrefillNeeded() && installationConstructionYear) {
                    this.gegStore.getHeatingSystemsForCurrentSystem(`${installationConstructionYear}`);
                    return EMPTY;
                }

                const options = this.formOptionsService.getHeatGeneratorDetailsFormOptions({
                    buildingType: project!.building.buildingType as BuildingType
                });

                const questions = this.formService.getHeatGeneratorDetailsQuestions(options);
                return of(questions);
            })
        );
    }

    private onHeatingSystems$() {
        return this.gegStore.currentSystemHeatingSystems$.pipe(
            distinctUntilChanged((prev, curr) => LodashService.isEqual(prev, curr)),
            withLatestFrom(
                this.gegStore.currentSystemEnergySource$,
                this.gegStore.currentSystemHeatGeneratorDetails$,
                this.projectStore.project$,
                this.gegStore.cache$
            ),
            map(([heatingSystems, energySource, heatGeneratorDetails, project, cache]) => ({
                heatingSystems,
                energySource,
                statePrefillSource: heatGeneratorDetails,
                cachePrefillSource: cache?.currentSystem?.heatGeneratorDetails,
                project
            })),
            mergeMap(state => {
                if (!this.isSimplePrefillNeeded()) {
                    return EMPTY;
                }

                if (!state.heatingSystems) {
                    return of(null);
                }

                const statePrefill = this.prefillMapper.stateToHeatGeneratorDetailsPrefill(state.statePrefillSource);
                const cachePrefill = this.prefillMapper.stateToHeatGeneratorDetailsPrefill(state.cachePrefillSource);
                const options = this.formOptionsService.getHeatGeneratorDetailsFormOptions({
                    buildingType: state.project!.building.buildingType as BuildingType,
                    prefill: statePrefill || cachePrefill,
                    energySourceState: state.energySource,
                    heatingSystems: state.heatingSystems
                });

                const questions = this.formService.getHeatGeneratorDetailsQuestions(options);

                return of(questions);
            })
        );
    }

    public getSuccessMessage(formValue: HeatGeneratorDetailsFormValue): string {
        const unit = this.translate.instant('MEASUREMENT_UNITS.KW');
        if (!formValue.boilerType) {
            return `${formValue.installationYear}, ${formValue.nominalPower} ${unit}`;
        }

        const installation = this.translate.instant(`HEATLOAD.HEAT_GENERATOR_DETAILS.BOILER_TYPE.${formValue.boilerType}`);
        return `${installation}, ${formValue.installationYear}, ${formValue.nominalPower} ${unit}`;
    }

    /**
     * Indicates a moment when a prefill with form value emition is needed.
     * Usually it changes its value once, initially.
     */
    public isPrefillNeeded(): boolean {
        return (
            !this.prefillService.isPrefilled(FormName.HeatGeneratorDetails, GEGSystemType.Current) &&
            !this.isConstructionYearPrefillFinished$.value
        );
    }

    /**
     * Indicates a moment when a prefill without form value emition is needed.
     * Usually it changes its value many times. Whenever the form has to be filled again
     * and there is a prefill source available (project data, or store cache data)
     */
    public isSimplePrefillNeeded() {
        return !this.isConstructionYearPrefillFinished$.value;
    }

    public afterPrefill(): void {
        this.prefillService.setAsPrefilled(FormName.HeatGeneratorDetails, GEGSystemType.Current);
    }

    public onPrefillInit(): void {
        this.toggleIsConstructionYearPrefillFinished(true);
    }

    public onSimplePrefillInit(): void {
        this.toggleIsConstructionYearPrefillFinished(true);
    }

    public isExpanded$ = this.gegStore.sameValueSubmited$.pipe(map(form => form?.formName === FormName.EnergySource));

    public toggleIsConstructionYearPrefillFinished(value: boolean): void {
        this.isConstructionYearPrefillFinished$.next(value);
    }
}
